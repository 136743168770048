import React from "react";
import "./style.css";

let NotFound = (props) => {
 
    return (
      <div className="main">
      <div className="fof">
        		<h1>404 | Not Found</h1>
    	</div></div>
    );
 
};
export default NotFound;
